export const UPDATE_FIELD_ATTRIBUTE = '@contactos/updateAttribute';
export const UPDATE_COMPANY_FIELD_ATTRIBUTE = '@contactos/updateCompanyAttribute';
export const SELECT_FORM_FIELD = '@contactos/updateFormType';
export const GET_USERS = '@contactos/users';
export const SELECT_TAB = '@contactos/tab';
export const SIGN_USER = '@contactos/signUser';
export const LOG_USER = '@contactos/logUser';
export const AUTH_SUCCESS = '@contactos/authSuccess';
export const AUTH_ERROR = '@contactos/authError';
export const AUTH_LOGIN_SUCCESS = '@contactos/loginSuccess';
export const INIT_USER_DATA = '@contactos/initUserData';
export const INIT_USER_SUCCESS = '@contactos/initUserSuccess';
export const LOAD_CATALOG = '@contactos/loadCatalog';
export const LOAD_CATALOG_SUCCESS = '@contactos/loadCatalogSuccess';
export const LOAD_DOCUMENT_SUCCESS = '@contactos/loadDocumentSuccess';
export const CHARGED_CATALOG = '@contactos/chargedCatalog';
export const CLOSE_MODAL = '@contactos/closeModal';
export const VALIDATE_FORM = '@contactos/validateForm';
export const CALL_DISABLED = '@contactos/callDisabled';
export const HIDDEN_COMPONENT = '@contactos/hiddenComponent';

export const SAVE_BD = '@contactos/saveBd';
export const CONTACTO_ADD = '@contactos/contactoAdd';
export const RELOAD_PAGE = '@contactos/reloadPage';
export const BASE_64 = '@contactos/crearBase64';
export const CARGAR_CATALOGOS = '@contactos/cargarCatalogos';
export const CARGAR_CARGO = '@contactos/cargarCargo';
export const DATA_AUTH = '@contactos/dataAuth';
export const CALL_DINAMIC_DISABLE = '@contactos/callDinamicDisable';
export const BASE64_ADD = '@contactos/base64Add';
export const BASE64_REMOVE = '@contactos/base64Remove';

export const SELECT_FOR_URL = "@contactos/selectForUrl";

export const TRATAMIENTO_DATOS = 'Autorizo el tratamiento de mis datos personales de acuerdo a la';
export const TRATAMIENTO_DATOSFinal = 'de la información de la Caja de Compensación Familiar Comfenalco Valle Delagente';

export const INIT_CONTACTO_SUCCESS = '@contactos/initUserSuccess';
export const INIT_CONTACTO_ERROR = '@contactos/initContactError';

export const PEOPLE_FORM_TITLE = 'para personas';
export const PEOPLE_FORM_SUBTITLE = 'Información de tu solicitud';
export const REDUX_CHANGE = '@@redux-form/CHANGE';

export const FORM_HEADER = 'Formulario de contáctanos'
//export const FORM_PARAGRAPH  = 'Tu opinión es muy importante para Comfenalco Valle, para nosotros es muy importante escucharte y resolver tus solicitudes, por eso queremos conocer tu experiencia con nuestros programas y servicios. Recuerda que la veracidad de tus datos nos facilita brindar una respuesta oportuna. Los campos señalados con el signo (*) son obligatorios.';

export const COMPANY_FORM_TITLE = 'para empresas';

export const COMPANY_FORM_SUB_TITLE = 'Datos de contacto';

export const COMPANY_FORM_SOLICITUD = 'Información de tu solicitud';

export const COMPANY_KEY = 'company';
export const PEOPLE_KEY = 'people';

export const CREATED_PEOPLE_OK = 'Persona registrada exitosamente';
export const CREATED_COMPANY_OK = 'Empresa registrada exitosamente';
export const REDUX_BLUR = '@@redux-form/BLUR';
export const DISABLE_PEOPLE_FORM = '@contactos/disablePeopleForm';

export const TAB_SING_IN = 'sign-in';
export const TAB_LOG_IN = 'log-in';
export const CREATE_COMFE_TICKET = '@contactos/createTicket';
export const UPLOAD_FILE = '@contactos/uploadFile';
export const UPLOAD_FILE_REDUCER = '@contactos/uploadReducer';
export const REMOVE_FILE = '@contactos/removeFile';
export const CLEAR_FILE = '@contactos/clearFile';
export const SAVE_CONSULTA_VALIDADOR = '@contactos/saveConsultaValidador';
export const CHANGE_TYPE = '@contactos/changeType';
export const INIT_INFO_SOLICITUD = '@contactos/initInfoSolicitud';
export const DATA_MOTIVO_SELECTED = '@contactos/dataMotivoSelected';

export const PARAMETRIZACION_DERECHO = [
    "DERECHO DE PETICION",
    "DERECHO DE PETICIÓN",

]
export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "content-type": "application/json;charset=iso-8859-1",
    "Cache-Control": "no-cache",
    'mode': "opaque ",
    auth: {
        username: "FORMULARIOSWEB",
        password: 'Yk2190_w'
    }
}

export const REQUIRED_COMPANY = [
    "documentCompanyType",
    "companyDocument",
    "socialName",
    "companyMobilePhone",
    "companyEmail",
    "companyTown",
    "companyState",
    "companySize",
];

export const REQUIRED_PERSONAL = [
    "abreviatura",
    "identificacion",
    "primerNombre",
    "primerApellido",
    "genero",
    "email",
    "celular",
    "departamento",
    "idMuniResidencia",
    "nacimiento",
];

export const REQUIRED_PERSONAL_COMPANY = [
    "abreviatura",
    "identificacion",
    "primerNombre",
    "primerApellido",
    "genero",
    "email",
    "celular",
    "departamento",
    "idMuniResidencia",
    "nacimiento",
    "cargo"
];

export const REQUIRED_SOLICITUD = [
    "requesType",
    "related",
    "goal",
    "site",
    "requestText",
    "habeasData",
    "requestCapcha",
];

export const OCULTAR_CAMPOS_FORM = {
    "identificacion": true,
    "primerNombre": true,
    "primerApellido": true,
    "genero": true,
    "email": true,
    "email2": true,
    "celular": true,
    "departamento": true,
    "idMuniResidencia": true,
    "nacimiento": true,
    "segundoNombre": true,
    "phone": true,
    "requesType": true,
    "related": true,
    "goal": true,
    "site": true,
    "requestText": true,
    "files": true,
    "habeasData": true,
    "personas": true,
    "empresas": false,
}
export const MOSTRAR_CAMPOS_FORM = {
    "abreviatura": false,
    "identificacion": false,
    "primerNombre": false,
    "primerApellido": false,
    "genero": false,
    "email": false,
    "email2": true,
    "celular": false,
    "departamento": false,
    "idMuniResidencia": false,
    "nacimiento": false,
    "segundoNombre": false,
    "phone": false,
    "requesType": false,
    "related": false,
    "goal": false,
    "site": false,
    "requestText": false,
    "files": false,
    "habeasData": false,
    "personas": false,
    "empresas": false,
}
export const MOSTRAR_CAMPOS_FORM_SELECTED = {
    "abreviatura": false,
    "identificacion": false,
    "primerNombre": false,
    "primerApellido": false,
    "genero": false,
    "email": false,
    "email2": true,
    "celular": false,
    "departamento": false,
    "idMuniResidencia": false,
    "nacimiento": false,
    "segundoNombre": false,
    "phone": false,
    "requesType": true,
    "related": true,
    "goal": true,
    "site": true,
    "requestText": false,
    "files": false,
    "habeasData": false,
    "personas": false,
    "empresas": false,
}
export const MOSTRAR_CAMPOS_FORM_VAlIDADOR = {
    "abreviatura": true,
    "identificacion": true,
    "primerNombre": true,
    "primerApellido": true,
    "genero": true,
    "email": true,
    "email2": true,
    "celular": true,
    "departamento": true,
    "idMuniResidencia": true,
    "nacimiento": true,
    "segundoNombre": true,
    "phone": true,
    "requesType": false,
    "related": false,
    "goal": false,
    "site": false,
    "requestText": false,
    "files": false,
    "habeasData": false,
}
export const MOSTRAR_CAMPOS_FORM_VAlIDADOR_SELECTED = {
    "abreviatura": true,
    "identificacion": true,
    "primerNombre": true,
    "primerApellido": true,
    "genero": true,
    "email": true,
    "email2": true,
    "celular": true,
    "departamento": true,
    "idMuniResidencia": true,
    "nacimiento": true,
    "segundoNombre": true,
    "phone": true,
    "requesType": true,
    "related": true,
    "goal": true,
    "site": true,
    "requestText": false,
    "files": false,
    "habeasData": false,
}
export const MOSTRAR_CAMPOS_EMPRESAS_VAlIDADOR_SELECTED = {
    "requesType": true,
    "related": true,
    "goal": true,
    "site": true,
}
export const MOSTRAR_CAMPOS_FORM_LISTA = [
    "abreviatura",
    "identificacion",
    "primerNombre",
    "primerApellido",
    "genero",
    "email",
    "celular",
    "departamento",
    "idMuniResidencia",
    "nacimiento",
    "segundoNombre",
    "phone",
    "requesType",
    "related",
    "goal",
    "site",
    "requestText",
    "files",
    "habeasData",
]



export const AUTH_HEADERS = [
    { label: "id", column: "id" },
    { label: "email", column: "email" },
    { label: "first name", column: "first_name" },
    { label: "last name", column: "last_name" },
    { label: " ", column: " " },
];

export const TOOGLE_FORM = [
    {
        label: false,
        name: "people",
        placeholder: "Personas",
        type: "selections",
        typeForm: "selections",
        value: false,
        options: [
            { label: 'Personas', code: 'people', className: "personas", name: 'persona', disable: false, placeholder: "Soy afiliado, beneficiario o persona natural" },
            { label: 'Empresas', code: 'company', className: "companys", name: 'empresa', disable: true, placeholder: "Quiero colocar una PQRS a nombre de una empresa" },
        ],
        disable: false,
        className: ""
    }
]

export const PEOPLE_FORM = [
    {
        placeholder: `Para iniciar diligencia los campos Tipo de identificación y Número de identificación con los cuales validaremos tus datos personales.`,
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccionC"
    },
    {
        label: "Tipo de identificación*",
        name: "abreviatura",
        placeholder: "Tipo de identificación*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: 'CC',
                label: 'Cédula de ciudadania'
            },
            {
                value: 'CE',
                label: 'Cédula de extranjería'
            },
            {
                value: 'PD',
                label: 'Pasaporte diplomático'
            },
            {
                value: 'PEP',
                label: 'Permiso especial de permanencia'
            },
            {
                value: 'SP',
                label: 'Salvoconducto de permanencia'
            },
        ],
        disable: false,
        tooltip: "Selecciona el tipo de documento que es aceptado como tu identificación oficial",
        className: "rs-50 form-group col-md-6 col-sm-12"
    },
    {
        tooltip: "Indica el número de tu identificación sin puntos",
        label: "Número de identificación*",
        name: "identificacion",
        placeholder: "Número de identificación",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Primer nombre*",
        name: "primerNombre",
        placeholder: "Primer nombre",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Segundo nombre",
        name: "segundoNombre",
        placeholder: "Segundo nombre",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Apellidos*",
        name: "primerApellido",
        placeholder: "Apellidos",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica el género con el que te identificas",
        label: "Género*",
        name: "genero",
        placeholder: "Género*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: 'F',
                label: 'Femenino'
            },
            {
                value: 'M',
                label: 'Másculino'
            },
            {
                value: 'NB',
                label: 'No Binario'
            }
        ],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Fecha de nacimiento*",
        name: "nacimiento",
        placeholder: "",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-50 dateFecha"
    },
    {
        tooltip: "Ingresa tu correo electrónico al que remitiremos la respuesta",
        label: "Correo electrónico*",
        name: "email",
        placeholder: "Correo electrónico",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica los diez (10) dígitos de tu número celular",
        label: "Teléfono celular*",
        name: "celular",
        placeholder: "000-000-0000",
        type: "number",
        typeForm: "number",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica los diez (10) dígitos de tu número fijo",
        label: "Teléfono fijo",
        name: "phone",
        placeholder: "000-000-0000",
        type: "number",
        typeForm: "number",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el departamento de tu lugar de residencia",
        label: "Departamento*",
        name: "departamento",
        placeholder: "Departamento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-50",

    },
    {
        tooltip: "Indica el municipio de tu lugar de residencia",
        label: "Municipio de residencia*",
        name: "idMuniResidencia",
        placeholder: "Municipio de residencia*",
        type: "select",
        typeForm: "select",
        value: false,
        charged: false,
        options: [],
        className: "rs-50",
        disable: false,

    },
    {
        placeholder: `Si deseas actualizar alguno de tus datos personales da clic en el siguiente boton que te llevara a nuestra sucursal virtual, inicia sesión y escoge la opción "Actualización de datos ".Regresa a este formulario y actualiza la página (presiona la tecla F5), tu información personal se actualizará de inmediato.`,
        label: "Actualizar datos",
        name: "instruccion",
        type: "buttonA",
        typeForm: "buttonA",
        value: false,
        options: ["https://virtual.comfenalcovalle.com.co/ServiciosWebRyA/index.html?tipoUsuario=p"],
        disable: false,
        className: "rs-100 instruccion2"
    },
]
export const PEOPLE_FORM2 = [
    {
        placeholder: "Diligencia los campos tipo de documento y documento de identificacion para validar la información suministrada.",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccionC"
    },
    {
        label: "Tipo de identificación*",
        name: "abreviatura",
        placeholder: "Tipo de identificación*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: 'CC',
                label: 'Cédula de ciudadania'
            },
            {
                value: 'CE',
                label: 'Cédula de extranjería'
            },
            {
                value: 'PD',
                label: 'Pasaporte diplomático'
            },
            {
                value: 'PEP',
                label: 'Permiso especial de permanencia'
            },
            {
                value: 'SP',
                label: 'Salvoconducto de permanencia'
            },
        ],
        disable: false,
        tooltip: "Selecciona el tipo de documento que es aceptado como tu identificación oficial",
        className: "rs-50 form-group col-md-6 col-sm-12"
    },
    {
        tooltip: "Indica el número de tu identificación sin puntos",
        label: "Número de identificación*",
        name: "identificacion",
        placeholder: "Número de identificación",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Primer nombre*",
        name: "primerNombre",
        placeholder: "Primer nombre",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Segundo nombre",
        name: "segundoNombre",
        placeholder: "Segundo nombre",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Apellidos*",
        name: "primerApellido",
        placeholder: "Apellidos",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica el género con el que te identificas",
        label: "Género*",
        name: "genero",
        placeholder: "Género*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [
            {
                value: 'F',
                label: 'Femenino'
            },
            {
                value: 'M',
                label: 'Másculino'
            },
            {
                value: 'NB',
                label: 'No Binario'
            }
        ],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Fecha de nacimiento*",
        name: "nacimiento",
        placeholder: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Ingresa tu correo corporativo al que remitiremos la respuesta",
        label: "Correo electrónico*",
        name: "email",
        placeholder: "Correo electrónico",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica los diez (10) dígitos de tu número celular",
        label: "Teléfono celular*",
        name: "celular",
        placeholder: "000-000-0000",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica los diez (10) dígitos de tu número fijo",
        label: "Teléfono fijo",
        name: "phone",
        placeholder: "000-000-0000",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el departamento de tu lugar de residencia",
        label: "Departamento*",
        name: "departamento",
        placeholder: "Departamento*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50",

    },
    {
        tooltip: "Indica el municipio de tu lugar de residencia",
        label: "Municipio de residencia*",
        name: "idMuniResidencia",
        placeholder: "Municipio de residencia*",
        type: "text",
        typeForm: "text",
        value: false,
        charged: false,
        options: [],
        className: "rs-50",
        disable: false,

    },
]
const PEOPLE_INTRUCTION = [
    {
        placeholder: "Selecciona tu tipo de solicitud a partir de las siguientes definiciones:",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 principal",

    },
    {
        options: ["Solicitud"],
        placeholder: ": cuando requieres información u orientación    ",
        type: "label",
        typeForm: "label",
        className: "rs-50"
    },
    {
        options: ["Comentario positivo"],
        placeholder: ": Si quieres compartirnos felicitaciones o reconocimientos.",
        type: "label",
        typeForm: "label",
        className: "rs-50"
    },
    {
        options: ["Sugerencia"],
        placeholder: ": Si deseas compartir expectativas o sugerencias.",
        type: "label",
        typeForm: "label",
        className: "rs-50"
    },
    {
        options: ["Queja"],
        placeholder: ": comentario de inconformidad con el comportamiento de un colaborador.",
        type: "label",
        typeForm: "label",
        className: "rs-50"
    },
    {
        options: ["Reclamo"],
        placeholder: ": comentario de insatisfacción acerca de nuestros procesos, prestación insuficiente de un servicio o vulneracion de tus derechos.",
        type: "label",
        typeForm: "label",
        className: "rs-100"
    },
]

export const generatePeopleSubForm = (handleUpload, handleRemove, Hclear) => {
    const PEOPLE_SUB_FORM = [
        {
            name: "informacionsolicitud",
            type: "section",
            typeForm: "section",
            className: "rs-100 instruccionPQR",
            fields: PEOPLE_INTRUCTION
        },
        {
            tooltip: "Seleccione a partir de la sección de ayuda",
            label: "Tipo de solicitud*",
            name: "requesType",
            placeholder: "Tipo de solicitud*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [{
                label: "Sugerencia",
                value: "Z04"
            }, {
                label: "Solicitud",
                value: "Z11"
            }, {
                label: "Comentario positivo",
                value: "Z05"
            }, {
                label: "Queja",
                value: "Z01"
            }, {
                label: "Reclamo",
                value: "Z02"
            }],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el servicio de la Caja de Compensación al cual quieres dirigir tu petición",
            label: "Relacionada con*",
            name: "related",
            placeholder: "Relacionada con*",
            type: "select",
            typeForm: "select",
            value: false,
            hidden: false,
            options: [
            ],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el motivo con el cual se relaciona tu petición",
            label: "Motivo*",
            name: "goal",
            placeholder: "Motivo*",
            type: "select",
            typeForm: "select",
            value: false,
            charged: false,
            options: [],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el lugar de Comfenalco Valle al cual quieres dirigir tu petición",
            label: "Sede*",
            name: "site",
            placeholder: "Sede*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-60",
            charged: false
        },
        {
            tooltip: "Detalla el motivo de tu petición, en lo posible especifica datos para ser mas efectivos con tu respuesta",
            label: "Descripción de tu solicitud* diligencia este campo suministrando información clara y detallada de tu requerimiento, para brindarte una atención oportuna en la respuesta",
            name: "requestText",
            placeholder: "Descripción de tu solicitud*",
            type: "textCharacter",
            typeForm: "textCharacter",
            value: false,
            options: [],
            disable: false,
            className: "rs-100-2 textAreaConfe"
        },
        {
            tooltip: "En caso de requerirlo, adjunta los documentos que amplían tu petición",
            label: "Adjunta documentos",
            name: "files",
            placeholder: "Subir",
            type: "file",
            typeForm: "file",
            value: false,
            options: [],
            disable: false,
            handleUpload,
            handleRemove,
            Hclear,
            className: "rs-100-2",
            multiple: false,
            auto: true,
            mode: true,
            maxFileSize: 7000000,
        },
        {
            label: "",
            name: "habeasData",
            type: "link",
            typeForm: "link",
            disabled: false,
            className: "rs-100-2"
        },
        {
            //tooltip: "Detalla el motivo de tu petición, en lo posible especifica datos para ser mas efectivos con tu respuesta",
            label: "",
            name: "requestCapcha",
            placeholder: "",
            type: "captcha",
            typeForm: "captcha",
            value: false,
            options: [],
            disable: false,
            className: "rs-100-2 captcha-valid"
        },
    ];

    return PEOPLE_SUB_FORM;
}

export const peopleContactForm2 = (handleUpload, handleRemove, Hclear) => {
    const PEOPLE_CONTACT_FORM = [

        {
            label: "Tus datos personales",
            name: "informacionpersonal",
            placeholder: "Para iniciar diligencia los campos Tipo de identificación y Número de identificación con los cuales validaremos tus datos personales",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: PEOPLE_FORM2
        },
        {
            label: "Información de tu solicitud",
            name: "informacionsolicitud",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: generatePeopleSubForm(handleUpload, handleRemove, Hclear)
        },
    ];

    return PEOPLE_CONTACT_FORM;
}
export const peopleContactForm = (handleUpload, handleRemove, Hclear) => {
    const PEOPLE_CONTACT_FORM = [

        {
            label: "Tus datos personales",
            name: "informacionpersonal",
            placeholder: "Diligencia los campos tipo de documento y documento de identificación para validar la información suministrada.",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: PEOPLE_FORM
        },
        {
            label: "Información de tu solicitud",
            name: "informacionsolicitud",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: generatePeopleSubForm(handleUpload, handleRemove, Hclear)
        },
    ];

    return PEOPLE_CONTACT_FORM;
}

export const COMPANY_FORM = [
    {
        tooltip: "",
        label: "Tipo de identificación Empresa*",
        name: "documentCompanyType",
        placeholder: "Tipo de identificación Empresa*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [

        ],
        disable: false,
        className: "form-control rs-50"
    },
    {
        tooltip: "Indica el número de identificación sin puntos y sin dígito de verificación",
        label: "Número de identificación empresa*",
        name: "companyDocument",
        placeholder: "Número de identificación empresa*",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Relaciona la denominación por la cual se conoce legalmente tu empresa",
        label: "Razón social*",
        name: "socialName",
        placeholder: "Razón social*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica los diez (10) dígitos del número celular corporativo",
        label: "Teléfono celular*",
        name: "companyMobilePhone",
        placeholder: "000-000-0000*",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica los diez (10) dígitos del número fijo de tu empresa.",
        label: "Teléfono fijo",
        name: "companyPhone",
        placeholder: "000-000-0000",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Ingresa el correo electrónico corporativo de tu empresa",
        label: "Correo electrónico*",
        name: "companyEmail",
        placeholder: "Correo electrónico",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica el departamento donde está ubicada la empresa",
        label: "Departamento de domicilio*",
        name: "companyState",
        placeholder: "Departamento de domicilio",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el municipio donde está ubicada la empresa",
        label: "Municipio de domicilio*",
        name: "companyTown",
        placeholder: "Municipio de domicilio",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Selecciona el tamaño de acuerdo al número de trabajadores de la empresa",
        label: "Tamaño empresa*",
        name: "companySize",
        placeholder: "Tamaño empresa",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [{
            "label": "Micro (Hasta 10 trabajadores)",
            "value": "1"
        }, {
            "label": "Pequeña (Entre 11 y 50 trabajadores)",
            "value": "2"
        }, {
            "label": "Mediana (Entre  51-100 trabajadores)",
            "value": "3"
        }, {
            "label": "Grande (Más de 100 trabajadores)",
            "value": "4"
        }],
        disable: false,
        className: "rs-50"
    },


]

export const COMPANY_SUB_FORM = [
    {
        label: "Tipo de identificación Empresa",
        name: "documentCompanyType",
        placeholder: "Tipo de identificación Empresa",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: '0',
                label: 'Cédula de ciudadania'
            },
            {
                value: '1',
                label: 'Cédula de extranjería'
            },
            {
                value: '2',
                label: 'Pasaporte diplomático'
            },
            {
                value: '3',
                label: 'Permiso especial de permanencia'
            },
            {
                value: '4',
                label: 'Salvoconducto de permanencia'
            },
        ],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Número de identificación empresa",
        name: "companyDocument",
        placeholder: "Número de identificación empresa",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
]

export const COMPANY_PEOPLE_FORM = [
    {
        placeholder: "Registra tu información corporativa en representación de la empresa, aquí remitiremos la respuesta",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccionC"
    },
    {
        tooltip: "Selecciona el tipo de documento que es aceptado como tu identificación oficial",
        label: "Tipo de identificación*",
        name: "abreviatura",
        placeholder: "Tipo de identificación*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [

        ],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el número de tu identificación sin puntos ",
        label: "Número de identificación*",
        name: "identificacion",
        placeholder: "Número de identificación*",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {

        label: "Primer nombre*",
        name: "primerNombre",
        placeholder: "Primer nombre*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Segundo nombre",
        name: "segundoNombre",
        placeholder: "Segundo nombre",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Apellidos*",
        name: "primerApellido",
        placeholder: "Apellidos*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-100-2"
    },
    {
        tooltip: "Indica el género con el que te identificas",
        label: "Género*",
        name: "genero",
        placeholder: "Género*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: 'F',
                label: 'Femenino'
            },
            {
                value: 'M',
                label: 'Másculino'
            },
            {
                value: 'No binario',
                label: 'NB'
            }
        ],
        disable: false,
        className: "rs-50"
    },
    {
        label: "Fecha de nacimiento*",
        name: "nacimiento",
        placeholder: "",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-50 dateFecha"
    },
    {
        tooltip: "Selecciona la ocupación que más se relaciona con tu cargo desempeñado",
        label: "Cargo*",
        name: "cargo",
        placeholder: "Cargo*",
        type: "autocomplete",
        typeForm: "autocomplete",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Ingresa tu correo corporativo al que remitiremos la respuesta",
        label: "Correo electrónico*",
        name: "email",
        placeholder: "Correo electrónico*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica los diez (10) dígitos de tu número celular corporativo",
        label: "Teléfono celular*",
        name: "celular",
        placeholder: "000-000-0000",
        type: "text",
        typeForm: "number",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica los diez (10) dígitos del número fijo de tu empresa.",
        label: "Teléfono fijo",
        name: "phone",
        placeholder: "000-000-0000",
        type: "text",
        typeForm: "number",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el departamento donde está ubicada la empresa",
        label: "Departamento*",
        name: "departamento",
        placeholder: "Departamento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-50"
    },
    {
        tooltip: "Indica el municipio donde está ubicada la empresa",
        label: "Municipio de residencia*",
        name: "idMuniResidencia",
        placeholder: "Municipio de residencia*",
        type: "select",
        typeForm: "select",
        value: false,
        charged: false,
        options: [],
        className: "rs-50",
        disable: false,
    },
]

export function companyPeopleSubForm(handleUpload, handleRemove, Hclear) {
    const COMPANY_PEOPLE_SUB_FORM = [


        {
            name: "informacionsolicitud",
            type: "section",
            typeForm: "section",
            className: "rs-100 instruccionPQR",
            fields: PEOPLE_INTRUCTION
        },
        {
            tooltip: "Seleccione a partir de la sección de ayuda",
            label: "Tipo de solicitud*",
            name: "requesType",
            placeholder: "Tipo de solicitud*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el servicio de la Caja de Compensación al cual quieres dirigir tu petición",
            label: "Relacionada con*",
            name: "related",
            placeholder: "Relacionada con*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el motivo con el cual se relaciona tu petición",
            label: "Motivo*",
            name: "goal",
            placeholder: "Motivo*",
            type: "select",
            typeForm: "select",
            value: false,
            charged: false,
            options: [],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Selecciona el lugar de Comfenalco Valle al cual quieres dirigir tu petición",
            label: "Sede*",
            name: "site",
            placeholder: "Sede*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-60",
            charged: false

        },
        {
            tooltip: "Detalla el motivo de tu petición, en lo posible especifica datos para ser mas efectivos con tu respuesta",
            label: "Descripción de tu solicitud* diligencia este campo suministrando información clara y detallada de tu requerimiento, para brindarte una atención oportuna en la respuesta",
            name: "requestText",
            placeholder: "Descripción de tu solicitud*",
            type: "textCharacter",
            typeForm: "textCharacter",
            value: false,
            options: [],
            disable: false,
            className: "rs-100-2 textAreaConfe"
        },
        {
            tooltip: "En caso de requerirlo, adjunta los documentos que amplían tu petición",
            label: "Adjunta documentos",
            name: "files",
            placeholder: "Subir",
            type: "file",
            typeForm: "file",
            value: false,
            options: [],
            disable: false,
            handleUpload,
            handleRemove,
            Hclear,
            className: "rs-100-2",
            multiple: false,
            auto: true,
            mode: true,
            maxFileSize: 7000000,
        },
        {
            label: "",
            name: "habeasData",
            type: "link",
            typeForm: "link",
            disable: false,
            className: "rs-100-2"
        },
        {
            //tooltip: "Detalla el motivo de tu petición, en lo posible especifica datos para ser mas efectivos con tu respuesta",
            label: "",
            name: "requestCapcha",
            placeholder: "",
            type: "captcha",
            typeForm: "captcha",
            value: false,
            options: [],
            disable: false,
            className: "rs-100-2 captcha-valid"
        },
    ]

    return COMPANY_PEOPLE_SUB_FORM;
}

export function getCompanyContactForm(handleUpload, handleRemove, Hclear) {
    const COMPANY_CONTACT_FORM = [
        {
            label: "Datos de la empresa",
            name: "companydataform",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: COMPANY_FORM

        },
        {
            label: COMPANY_FORM_SUB_TITLE,
            name: "informacionpersonal",
            type: "section",
            typeForm: "section",
            className: "rs-100 class_test",
            fields: COMPANY_PEOPLE_FORM
        },
        {
            label: COMPANY_FORM_SOLICITUD,
            name: "companyrequestform",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: companyPeopleSubForm(handleUpload, handleRemove, Hclear)
        },
    ]

    return COMPANY_CONTACT_FORM;
}

export const getLoginButton = (handlerSafe) => {
    return [
        {
            label: "Enviar",
            className: "auth-btn-send",
            action: false,
            style: "primary",
            primary: true,
            onClick: handlerSafe
        }
    ]
}

export const getTabButton = (handlerSafe) => {
    return [
        {
            label: "Persona",
            name: "personas",
            className: "auth-btn-togle-personas",
            action: false,
            style: "primary",
            primary: true,
            disabled: true,
            options: "Si eres afiliado, beneficiario, no afiliado o persona natural",
            onClick: handlerSafe
        },
        {
            label: "Empresa",
            name: "empresas",
            className: "auth-btn-togle-empresas",
            action: false,
            disabled: false,
            options: "Si tu petición es en nombre o representación de una empresa",
            style: "primary",
            primary: true,
            onClick: handlerSafe
        }
    ]
}


export const getSignButton = (handlerSafe) => {
    return [
        {
            label: "Sign in",
            className: "auth-btn-sign",
            action: false,
            style: "primary",
            onClick: handlerSafe
        }
    ]
}

export const tabs = (selectTab) => {
    return [
        {
            label: "Login in",
            className: "auth-tab-sign",
            key: TAB_LOG_IN,
            onClick: selectTab
        }
    ];
}